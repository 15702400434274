export function map(){
    const lat = 44.84394365679986;
    const lng = 20.378819769666332;

    // Making a map and tile
    const mymap = L.map('map', {
        scrollWheelZoom: false
    }).setView([lat, lng], 13);

    const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?';
    const tiles = L.tileLayer(tileUrl, { attribution });

    tiles.addTo(mymap);
    const marker = L.marker([lat, lng]).addTo(mymap);
}