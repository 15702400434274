const btnPrev = document.querySelector('.arrow-button.arrow-button--left');
const btnNext = document.querySelector('.arrow-button.arrow-button--right');



// Postavljam strana= na kraju query string-a

export function prevP(){
    if(btnPrev){
        btnPrev.addEventListener('click', function(e){
            e.preventDefault();
            correctPage(-1);
        });
    }
}


export function nextP(){
    if(btnNext){
        btnNext.addEventListener('click', function(e){
            e.preventDefault();
            correctPage(1);
        });
    }
}


function correctPage(add){
    const currentPage = document.querySelector('.page-num__text').dataset.current_page;
    let url = window.location.href;
    const original = `strana=${currentPage * 1 + add}`;

    url = createPageUrl(url, original);
    window.location.href = url;
}


function createPageUrl(url, original){

    // 1 - query string ne sadrzi strana
    if(!url.includes('strana')){
        return url = `${url}&${original}`;
    }

    // 2 - na kraju &strana=
    if(url.includes('&strana')){
        var urlNiz = url.split('&');
        var visak = urlNiz[urlNiz.length - 1];
        return url = url.replace(visak, original);
    }

};


export function clickableNames(){
    const allProducts = document.querySelector('.row.all-products');
    allProducts.addEventListener('click', function(e){
        const productName = e.target.closest('.product-box__name');
        if(!productName) return;
        const url = productName.closest('.product-box').children[0].href;
        window.location.href = url;
    });
}