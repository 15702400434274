import { hideAlert, showAlert } from './alert.js';


const smallPics = document.querySelectorAll('.small-pictures-box img');
const bigPics = document.querySelectorAll('.big-picture-box .img');
let activeBigPic = bigPics[0];


const regulateActive = function(e){
    // console.log('🏀 e.target: ', e.target);
    const position = e.target.dataset.position * 1;
    // console.log('🏀 position: ', position);

    activeBigPic.classList = 'img';
    activeBigPic = bigPics[position];
    activeBigPic.classList = 'img img--visible';
}


export function showBigPicture(){
    smallPics.forEach(smallPic => smallPic.addEventListener('click', regulateActive));
}


export function addToCart(){
    // let url = location.href;
    let url = '/korpa';

    const addBtn = document.querySelector('.cart-qt-box .confirm-button');
    const qtBtn = document.querySelector('.cart-qt-box input');

    addBtn.addEventListener('click', async function(e){
        e.preventDefault();
        let addQuantity;
        addQuantity = qtBtn.value * 1;

        const product = {
            id: addBtn.dataset.id,
            naziv: addBtn.dataset.naziv,
            kategorija: addBtn.dataset.kategorija,
            cena: addBtn.dataset.cena,
            cenaNaPopustu: addBtn.dataset.cena_na_popustu,
            slika: addBtn.dataset.slika
        }

        try {
            await axios({
                method: 'post',
                url: `/cart`,
                data: {
                    product,
                    addQuantity
                }
            });
            location.assign(url);
        
        } catch(err) {
            console.log(err);
        }
    });

}

export function fillBreadCrumb(){
    const productCategory = document.querySelector('.confirm-button').dataset.kategorija;

    // Search data in mega menu for filling in bread crumb
    const wantedEl = document.querySelector(`.mega-menu-box .mega-menu__link[href='/kategorija?kategorija=${productCategory.toLowerCase()}']`);
    const hrefValue = wantedEl.getAttribute('href');
    const textValue = wantedEl.textContent;

    // console.log('🏀 textValue:', textValue);
    // console.log('🏀 hrefValue:', hrefValue);

    // Create bread crumb
    const el = `
        <div class="bread-crumb">
            <span>
                <a class="bread-crumb__link" href="/">Početna</a>
            </span>
            <span>&nbsp / &nbsp</span>
            <span>
                <a class="bread-crumb__link" href="${hrefValue}">${textValue}</a>
            </span>
        </div>
    `;

    // Insert bread crumb on page
    document.querySelector('main').insertAdjacentHTML('afterbegin', el);

}