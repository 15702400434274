import { showAlert } from './alert.js';


export function goToCart() {
    const cartBtn = document.querySelector('.header__icon--cart path');
    const cartQt = document.querySelector('.cart-number').textContent;
    cartBtn.addEventListener('click', () => {
        if (!cartQt || cartQt === '0') return showAlert('error', 'Vaša korpa je prazna.');
        location.href = '/korpa';
    }); 
}


export function increaseInCart(){
    let url = location.href;
    const signBtns = document.querySelectorAll('.quantity');

    for(let i = 0; i < signBtns.length; i++){
        signBtns[i].addEventListener('click', async function(e){
            const productData = e.target.closest('.quantity');

            // Formiranje proizvoda
            const product = {
                id: productData.dataset.id,
                naziv: productData.dataset.naziv,
                kategorija: productData.dataset.kategorija,
                cena: productData.dataset.cena,
                cenaNaPopustu: productData.dataset.cena_na_popustu,
                slika: productData.dataset.slika
            }
            // console.log('🏀 product: ', product);

            // 1 - Klik na plus
            if(e.target.closest('.quantity__sign--plus')){
                try {
                    await axios({
                        method: 'post',
                        url: `/cart`,
                        data: {
                            product
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            // 2 - Klik na minus
            } else if (e.target.closest('.quantity__sign--minus')){
                try {
                    await axios({
                        method: 'post',
                        url: `/cart`,
                        data: {
                            product,
                            addQuantity: -1
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            }
            
        });
    }
    
}


export function deleteItem(){
    let url = location.href;
    const removeBtns = document.querySelectorAll('.remove');

    for (let i = 1; i < removeBtns.length; i++ ){

        // Klik na kantu
        removeBtns[i].addEventListener('click', async function(e){
            if(e.target.closest('.remove')){
                const target = e.target.closest('.remove');
                const oldQuantity = target.parentElement.children[4].children[1].textContent * 1;

                // Formiranje proizvoda
                const product = {
                    id: target.parentElement.children[4].dataset.id,
                    naziv: target.parentElement.children[4].dataset.naziv,
                    kategorija: target.parentElement.children[4].dataset.kategorija,
                    cena: target.parentElement.children[4].dataset.cena,
                    cenaNaPopustu: target.parentElement.children[4].dataset.cena_na_popustu,
                    slika: target.parentElement.children[4].dataset.slika
                }
                
                try {
                    await axios({
                        method: 'post',
                        url: `/cart`,
                        data: {
                            product,
                            addQuantity: oldQuantity * -1
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            }
        });

    }
    
}


export function whatShows(){
    const deliveryCost = 300;

    const whoPaysBtns = document.querySelectorAll('.radio-group--who-pays input');
    const pibBtn = document.querySelector('.billing-details-form input:first-child');
    const companyBtn = document.querySelector('.billing-details-form #kompanija');

    // Dugmici za nacin placanja
    const pouzecePaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[2];
    // const pouzecePaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[2];
    const cardPaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[3];
    // const cardPaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[3];
    const uplatnicaPaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[0];
    // const uplatnicaPaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[0];
    const virmanPaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[1];
    // const virmanPaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[1];

    // Dugmici za isporuku
    // const courierDeliveryBtn = document.querySelectorAll('.radio-group.radio-group--delivery')[0];
    const courierDeliveryBtnInput = document.querySelectorAll('.radio-group.radio-group--delivery input')[0];
    // const inServisDeliveryBtn = document.querySelectorAll('.radio-group.radio-group--delivery')[1];
    const inServisDeliveryBtnInput = document.querySelectorAll('.radio-group.radio-group--delivery input')[1];

    // Summary table
    let productsAmount = document.querySelector('.summary-table--products');
    let isporuka = document.querySelector('.summary-table--delivery');
    let ukupnoZaPlacanje = document.querySelector('.summary-table--total-price');


    // Placanje preko firme
    whoPaysBtns[1].addEventListener('click', () => {
        pibBtn.setAttribute('style', 'display: block');
        companyBtn.setAttribute('style', 'display: block');

        virmanPaymentBtn.style.display = 'block';
        uplatnicaPaymentBtn.setAttribute('style', 'display: none');
        pouzecePaymentBtn.setAttribute('style', 'display: none');
        cardPaymentBtn.setAttribute('style', 'display: none');
    });

    // Placanje kao fizicko lice
    whoPaysBtns[0].addEventListener('click', () => {
        pibBtn.setAttribute('style', 'display: none');
        companyBtn.setAttribute('style', 'display: none');

        virmanPaymentBtn.style.display = 'none';
        uplatnicaPaymentBtn.setAttribute('style', 'display: block');
        pouzecePaymentBtn.setAttribute('style', 'display: block');
        cardPaymentBtn.setAttribute('style', 'display: block');
    }); 

    // Nacini placanja
    // pouzecePaymentBtnInput.addEventListener('click', () => courierDeliveryBtn.style.display = 'block');
    // cardPaymentBtnInput.addEventListener('click', () => courierDeliveryBtn.style.display = 'block');


    inServisDeliveryBtnInput.addEventListener('click', () => {
        isporuka.dataset.amount = 0;
        isporuka.textContent = `0 rsd`;
        ukupnoZaPlacanje.dataset.amount = productsAmount.dataset.amount * 1;
        ukupnoZaPlacanje.textContent = `${(productsAmount.dataset.amount * 1).toLocaleString()} rsd`;
    });


    courierDeliveryBtnInput.addEventListener('click', () => {
        isporuka.dataset.amount = deliveryCost;
        isporuka.textContent = `300 rsd`;
        ukupnoZaPlacanje.dataset.amount = productsAmount.dataset.amount * 1 + deliveryCost;
        ukupnoZaPlacanje.textContent = `${(productsAmount.dataset.amount * 1 + deliveryCost).toLocaleString()} rsd`;
    });
    
}


export async function collectPurchaseData(){
    // return console.log('🏀 CS f-ja');

    const cart = [];
    let item = {}
    let productItems = document.getElementsByClassName('main-table__product-row');

    let productsTotalPrice;
    let delivery;
    let totalPrice;

    const orderBtn = document.querySelector('.section-billing-details .confirm-button');

    let whoPays;
    let selectedPayment;
    let acceptanceMethod;

    let pib;
    let company;
    let fullName;
    let phone;
    let address;
    let postCode;
    let email;
    let message;


    const collectData = async () => {

        // 1 - Prikupljanje podataka

        // Prikupljanje proizvoda u korpi u varijablu cart
        for (let i = 0; i < productItems.length; i++) {
            item = {
                name: productItems[i].children[2].textContent,
                price: productItems[i].children[3].textContent,
                quantity: productItems[i].children[4].children[1].textContent,
                subtotal: productItems[i].children[5].textContent
            }
            cart.push(item);
        }
    
        productsTotalPrice = document.querySelector('.summary-table .summary-table__row').children[1].textContent;
        delivery = document.querySelector('.summary-table .summary-table--delivery').textContent;
        totalPrice = document.querySelector('.summary-table .summary-table--total-price').textContent;


        if(document.querySelector('.radio-group--who-pays input[type="radio"]:checked')) {
            whoPays = document.querySelector('.radio-group--who-pays input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite ko plaća! Fizičko lice ili firma?') }

        if(document.querySelector('.radio-group--payment input[type="radio"]:checked')) {
            selectedPayment = document.querySelector('.radio-group--payment input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite način plaćanja') }

        if(document.querySelector('.radio-group--delivery input[type="radio"]:checked')){
            acceptanceMethod = document.querySelector('.radio-group--delivery input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite način preuzimanja') }
        
        // Mozda se pib i kompanija ne pojave u zavisnosti placa li fizicko ili pravno lice 🔑
        if (document.getElementById('pib').style.display == 'block'){
            pib = document.getElementById('pib').value;
            if(!pib) return showAlert('error', 'Unesite PIB firme');
        }
        if (document.getElementById('kompanija').style.display == 'block'){
            company = document.getElementById('kompanija').value;
            if(!company) return showAlert('error', 'Unesite naziv firme');
        }

        fullName = document.getElementById('ime').value;
        if(!fullName) return showAlert('error', 'Unesite ime');

        phone = document.getElementById('tel').value;
        if(!phone) return showAlert('error', 'Unesite telefon');

        address = document.getElementById('adresa').value;
        if(!address) return showAlert('error', 'Unesite adresu');

        postCode = document.getElementById('posta').value;
        if(!postCode) return showAlert('error', 'Unesite poštanski broj');

        email = document.getElementById('email').value;
        if(!email) return showAlert('error', 'Unesite email');

        message = document.getElementById('message').value;

        // console.log('🏀 whoPays: ', whoPays);
        // console.log('🏀 selectedPayment: ', selectedPayment);
        // console.log('🏀 acceptanceMethod: ', acceptanceMethod);


        // Kontrola loseg unosa
        if(whoPays === 'Plaćam kao fizičko lice' && selectedPayment === 'Virman - pravna lica'){
            return showAlert('error', 'Izaberite način plaćanja');
        }
        if(whoPays === 'Plaćam preko firme' && selectedPayment !== 'Virman - pravna lica'){
            return showAlert('error', 'Izaberite način plaćanja');
        }

        // return;
        // Ako placa:
        // - fizicko lice pouzecem ili uplatnicom,
        // - pravno lice virmanski
        if(selectedPayment !== 'Online plaćanje platnim karticama'){
            // 2 - Slanje emaila ka kupcu i prodavcu
            try{
                const successfullBooking = await axios({
                    url: 'api/v1/bookings/emails',
                    method: 'post',
                    data: {
                        pib,
                        company,
                        fullName,
                        email,
                        address,
                        postCode,
                        phone,
                        message,
                        delivery,
                        productsTotalPrice,
                        totalPrice,
                        whoPays,
                        acceptanceMethod,
                        selectedPayment
                    }
                });
                // console.log(successfullBooking); // **

                if(successfullBooking.status == 200){
                    // console.log('🏀 successfullBooking: ', successfullBooking);
                    showAlert('success', 'Uspešna porudžbina. Proverite Vaš email');
                    // return; // ⏳
        
                    // * Bookiranje ako je logovan korisnik
                    const signoutBtn = document.querySelector('.signout');
                    if (signoutBtn !== null){
                        const sendBody = {
                                user: signoutBtn.dataset.user_id,
                                price: document.querySelector('.summary-table--total-price').dataset.amount * 1,
                                paymentType: selectedPayment,
                                purchase: signoutBtn.dataset.cart
                        };
                        
                        await axios({
                            url: '/api/v1/bookings',
                            method: 'post',
                            data: sendBody
                        });
                    }

                    // 3 - Brisanje korpe
                    await axios({
                        url: '/cart',
                        method: 'delete'
                    });
        
                    // 4 - Usmeravanje na zeljenu stranu
                    window.setTimeout(() => location.assign('/'), 5 * 1000); //
                }
            } catch(err){
                console.log(err);
            }
        // Ako se placa karticom
        } else {
            // 1 - Ubacivanje podataka kupca u req.session.buyerData
            try{
                var res = await axios({
                    url: '/cart/buyer',
                    method: 'post',
                    data: {
                        whoPays,
                        fullName,
                        phone,
                        address,
                        postCode,
                        email,
                        message,
                        selectedPayment,
                        acceptanceMethod,
                        productsTotalPrice,
                        delivery,
                        totalPrice
                    }
                });
                if(res.status == 201) showAlert('success', 'Uspešno slanje podataka. Bićete usmereni na stranicu za unos podataka o kartici.');

            } catch(err) {
                showAlert('error', 'Došlo je do greške, pokušajte ponovo!');
            }

            // 2 - Usmeravanje na stranu koja aktivira zahtev ka All-secure, korak priprema checkout-a
            window.setTimeout(() => location.assign('/porucivanje/placanje'), 3 * 1000);
        }
        
      
    }


    orderBtn.addEventListener('click', collectData);

}