import { showAlert } from './alert.js';


export function whatShowsAdmin(){
    const visibleBoxes = document.querySelectorAll('.visible-box');
    const topicLinks = document.querySelectorAll('.topic-box__link');

    topicLinks.forEach(link => {
        link.addEventListener('click', function(e) {
            // console.log('🏀 e.target: ', e.target);
            const targetClass = e.target.dataset.show;
            visibleBoxes.forEach(el => el.style.display = 'none');
            document.querySelector(`.${targetClass}`).style.display = 'block';
        });
    });
}


export async function setRabate(){
    const section = document.querySelector('.visible-box.visible-box--rabates');

    const userIdBtn = section.querySelector('.input.input--user-id');
    const generalRabateBtn = section.querySelector('.input.input--general-rebate');
    const chosenProductBtn = section.querySelector('.input.input--product-name');
    const productRabateBtn = section.querySelector('.input.input--product-rebate');
    const submitBtn = section.querySelector('.input.input--submit');

    submitBtn.addEventListener('click', async function(){
        // console.log('🏀 click: ');
        const bodyToSend = {};
        
        if (generalRabateBtn.value != '') bodyToSend.generalRebate = generalRabateBtn.value * 1;
        if (chosenProductBtn.value != '' && chosenProductBtn.value != '') bodyToSend.productRebate = [{ productName: chosenProductBtn.value, rebate: productRabateBtn.value * 1 }];

        // console.log('🏀 bodyToSend: ', bodyToSend);
        // console.log('🏀 userIdBtn.value: ', userIdBtn.value);
        // console.log('🏀 chosenProductBtn.value: ', chosenProductBtn.value);
        // return;

        try {
            const res = await axios({
                method: 'patch',
                url: `/api/v1/users/${userIdBtn.value}`,
                data: bodyToSend
            });    

            if(res.data.status === 'success') {
                showAlert('success', 'Uspešno ste izvršili podešavanje');
                window.setTimeout(() => location.assign('/admin'), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
        }

    });
}


export function getPurchaseHistory(){
    const section = document.querySelector('.visible-box.visible-box--purchase-history');
    const userIdBtn = section.querySelector('.input.input--user-id');
    const submitBtn = section.querySelector('.input.input--submit');

    submitBtn.addEventListener('click', function(){
        location.assign(`/istorijat-kupovina/${userIdBtn.value}`);
    });
}


export async function addProduct(){
    const section = document.querySelector('.visible-box.visible-box--add-product');

    const sendForm = async (e) => {
        // e.preventDefault();
        // console.log('🏀 section...: ', section.querySelector('.input--product-main-photo').files);
        // console.log('🏀 section...: ', section.querySelector('.input--product-other-photos').files);

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();

        // Requries fields
        form.append('naziv', section.querySelector('.input--product-name').value);
        form.append('kategorija', section.querySelector('.input--product-category').value);
        form.append('opis', section.querySelector('.input--product-description').value);
        form.append('cena', section.querySelector('.input--product-price').value);
    
        // Optional fields
        if(section.querySelector('.input--product-instruction').value){
            form.append('uputstvo', section.querySelector('.input--product-instruction').value);
        }
        if(section.querySelector('.input--product-price-discount').value){
            form.append('cenaNaPopustu', section.querySelector('.input--product-price-discount').value);
        }
        if(section.querySelector('.input--product-related').value){
            form.append('povezaniProizvodi', section.querySelector('.input--product-related').value);
        }
        if(section.querySelector('.input--product-stock').value){
            form.append('kolicinaNaStanju', section.querySelector('.input--product-stock').value);
        }
        if(section.querySelector('.input--product-visible').value){
            form.append('vidljivProizvod', section.querySelector('.input--product-visible').value);
        }
        if(section.querySelector('.input--product-main-photo').files[0]){
            // console.log('🏀', section.querySelector('#slika').files[0]);
            form.append('mainPhoto', section.querySelector('.input--product-main-photo').files[0]);
        }

        // Other photos
        let numOfFiles = section.querySelector('.input--product-other-photos').files.length;
        for (let i = 0; i < numOfFiles; i++) {
            form.append('otherPhotos', section.querySelector('.input--product-other-photos').files[i]);
        }
        

        // 2 - Provera unetih podataka
        if(!section.querySelector('.input--product-name').value) return showAlert('error', 'Obavezno je navesti naziv');
        if(!section.querySelector('.input--product-category').value) return showAlert('error', 'Obavezno je navesti kategoriju');
        if(!section.querySelector('.input--product-description').value) return showAlert('error', 'Obavezno je navesti opis');
        if(!section.querySelector('.input--product-price').value) return showAlert('error', 'Obavezno je navesti cenu');


        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'post',
                url: '/admin/product',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste dodali proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            // console.log('🏀 err.response: ', err.response);
            showAlert('error', err.response.data.message);
            // console.log('🏀 err: ', err);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    section.querySelector('.input--submit').addEventListener('click', sendForm);
}


export async function editProduct(){
    const section = document.querySelector('.visible-box--edit-product');

    const sendForm = async (e) => {
        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('nameEntry', section.querySelector('.input--product-nameEntry').value);

        if(section.querySelector('.input--product-category').value){
            form.append('kategorija', section.querySelector('.input--product-category').value);
        }
        if(section.querySelector('.input--product-description').value){
            form.append('opis', section.querySelector('.input--product-description').value);
        }
        if(section.querySelector('.input--product-instruction').value){
            form.append('uputstvo', section.querySelector('.input--product-instruction').value);
        }
        if(section.querySelector('.input--product-price').value){
            form.append('cena', section.querySelector('.input--product-price').value);
        }
        if(section.querySelector('.input--product-price-discount').value){
            form.append('cenaNaPopustu', section.querySelector('.input--product-price-discount').value);
        }
        if(section.querySelector('.input--product-related').value){
            form.append('povezaniProizvodi', section.querySelector('.input--product-related').value);
        }
        if(section.querySelector('.input--product-stock').value){
            form.append('kolicinaNaStanju', section.querySelector('.input--product-stock').value);
        }
        if(section.querySelector('.input--product-visible').value){
            form.append('vidljivProizvod', section.querySelector('.input--product-visible').value);
        }
        if(section.querySelector('.input--product-main-photo').files[0]){
            // console.log('🏀', section.querySelector('#slika').files[0]);
            form.append('mainPhoto', section.querySelector('.input--product-main-photo').files[0]);
        }

        // Other photos
        let numOfFiles = section.querySelector('.input--product-other-photos').files.length;
        for (let i = 0; i < numOfFiles; i++) {
            form.append('otherPhotos', section.querySelector('.input--product-other-photos').files[i]);
        }

        // 2 - Provera unetih podataka
        if(!section.querySelector('.input--product-nameEntry').value) return showAlert('error', 'Obavezno je navesti naziv proizvoda koji menjate');

        // 3 - http zahtev
        try {
            const res = await axios({
                method: 'patch',
                url: '/admin/product',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste izmenili proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    section.querySelector('.input--submit').addEventListener('click', sendForm);
}


export async function deleteProduct(){
    const section = document.querySelector('.visible-box--delete-product');

    const sendForm = async (e) => {
        let nameEntry = section.querySelector('.input--product-nameEntry').value;
        if(!nameEntry) return showAlert('error', 'Obavezno je navesti naziv');
        
        try {
            const res = await axios({
                method: 'delete',
                url: '/admin/product',
                data: {
                    nameEntry
                }
            });
            // console.log('🏀 res: ', res);

            if(res.status == 204){
                showAlert('success', 'Uspešno ste izbrisali proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    section.querySelector('.input--submit').addEventListener('click', sendForm);
}


export async function setActionProducts(){
    const section = document.querySelector('.visible-box--set-action-products');

    const setProducts = async (e) => {
        let nameEntry = section.querySelector('.input--product-nameEntry').value;
        if(!nameEntry) return showAlert('error', 'Obavezno je navesti nazive proizvoda');
        // return console.log('🏀 nameEntry: ', nameEntry);
        nameEntry = nameEntry.split(',').map(el => el.trim());
        
        try {
            const res = await axios({
                method: 'post',
                url: '/api/v1/actions',
                data: {
                    productsOnAction: nameEntry
                }
            });
            // console.log('🏀 res: ', res);

            if(res.status == 201){
                showAlert('success', 'Uspešno ste postavili istaknute proizvode.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    section.querySelector('.input--submit').addEventListener('click', setProducts);
}