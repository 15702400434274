// const slider = document.querySelector('.slider');
const iconLeft = document.querySelector('.icon-box .icon__left');
const iconRight = document.querySelector('.icon-box .icon__right');
let currentSlide = 0;

// ⏳ Ubaci prave proizvode iz baze u data- nekog elementa, ili kroz pug
// const numOfElements = 5; 
const productBoxes = document.querySelectorAll('.product-box');


const setPrev = () => {
    currentSlide--;
    // const productBoxes = document.querySelectorAll('.product-box');
    if(currentSlide === -1) currentSlide = productBoxes.length - 1;

    productBoxes.forEach((productBox, i) => {
        productBox.style.transform = `translateX(${(i - currentSlide) * 100}%)`;
    });
}


const setNext = () => {
    currentSlide++;
    // const productBoxes = document.querySelectorAll('.product-box');
    if(currentSlide === productBoxes.length) currentSlide = 0; 

    productBoxes.forEach((productBox, i) => {
        productBox.style.transform = `translateX(${(i - currentSlide) * 100}%)`;
    });
    // console.log('🏀 currentSlide: ', currentSlide);
}

// 🏄🏻‍♂️ Ako idem preko ubacivanja elemenata sa klijent strane
// const insertElements = () => {
//     let elementsToInsert = [];
//     for (let i = 0; i < numOfElements; i++){
//         let el = `
//             <div class="product-box product-box--${i}">
//                 <a href="#">
//                     <img class="product-box__img" src="./img/Logo_formula.jpg" alt="Naziv proizvoda">
//                 </a>
//                 <h2 class="product-box__head">Naziv proizvoda ${i}</h2>
//             </div>
//         `;

//         elementsToInsert.push(el);
//     }
//     elementsToInsert = elementsToInsert.join('');
//     slider.insertAdjacentHTML('afterbegin', elementsToInsert);
// }


const positionElements = () => {
    const productBoxes = document.querySelectorAll('.product-box');
    for (let i = 0; i < productBoxes.length; i++){
        // console.log('🏀 productBoxes[i]: ', productBoxes[i]);
        productBoxes[i].style.transform = `translateX(${i * 100}%)`;
    }
}


export default function (){
    if(!iconLeft) return;
    positionElements();
    iconRight.addEventListener('click', () => setNext());
    iconLeft.addEventListener('click', () => setPrev());
}