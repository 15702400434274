import { showAlert } from './alert.js';


export async function resetPassword(){
    document.querySelector('.reset .input--submit').addEventListener('click', async function(e) {
        // e.preventDefault();
        const password = document.querySelector('.reset .input--password').value;
        const passwordConfirm = document.querySelector('.reset .input--password-confirm').value;
        const token = location.pathname.split('resetStrana/')[1];
        // return console.log('🏀 password, passwordConfirm, token: ', password, passwordConfirm, token);

        try{
            const res = await axios({
                method: 'patch',
                url: `/api/v1/users/resetPassword/${token}`,
                data: {
                    password,
                    passwordConfirm
                }
            });
        
            // console.log('🏀 res.data: ', res.data);
            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste promenili šifru');
            }
            window.setTimeout(() => location.assign('/'), 5 * 1000); //
    
        } catch(err){
            showAlert('error', err.response.data.message);
        }
    });
}