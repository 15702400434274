const servicesBtn = document.querySelector('.nav__item--mega-menu');
const megaMenuBox = document.querySelector('.mega-menu-box');
const iconOpen = document.querySelector('.header__icon--open');
const iconClose = document.querySelector('.header__icon--close');
const nav = document.querySelector('.header .nav');
const mainEl = document.querySelector('main');
let isShowed = false;

const showMegaMenu = () => {
    // ❔ Podesi visinu u zavisnosti od stavki u megameni-ju
    servicesBtn.style = 'height: 160rem'; 
    megaMenuBox.style = 'display: block';
    isShowed = true;
}

const hideMegaMenu = () => {
    servicesBtn.style = 'height: 5rem';
    megaMenuBox.style = 'display: none';
    isShowed = false;
}

const openMenu = () => {
    nav.style = 'display: block';
    iconOpen.style = 'display: none';
    iconClose.style = 'display: inline-block';
    mainEl.style = 'display: none';
}

const closeMenu = () => {
    nav.style = 'display: none';
    iconOpen.style = 'display: inline-block';
    iconClose.style = 'display: none';
    mainEl.style = 'display: block';
}

export default function(){
    // Menu
    iconOpen.addEventListener('click', () => openMenu());
    iconClose.addEventListener('click', () => {
        hideMegaMenu();
        closeMenu();
    });

    // Mega menu
    servicesBtn.addEventListener('click', () => isShowed ? hideMegaMenu() : showMegaMenu());
}