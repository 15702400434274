import { showAlert } from './alert.js';

export async function login(){
    // console.log('🏀 login f-ja: ');

    document.querySelector('.section-login .input--submit').addEventListener('click', async function(e) {
        // console.log('🏀 klik: ');
        // e.preventDefault();
        const email = document.querySelector('.section-login .input--email').value;
        const password = document.querySelector('.section-login .input--password').value;
        // return console.log('🏀 email, password: ', email, password);

        try{
            const res = await axios({
                method: 'post',
                url: '/api/v1/users/login',
                data: {
                    email: email,
                    password: password
                }
            });
        
            // console.log('🏀 res: ',res);
            location.assign('/');
    
        } catch(err){
            showAlert('error', err.response.data.message);
        }
    });
}


export const logout = async function(){
    const logoutBtn = document.querySelector('.account-box.account-box--logout .signout');
    // console.log('🏀 logoutBtn: ', logoutBtn);
    if(!logoutBtn) return;

    logoutBtn.addEventListener('click', async function(e){
        // console.log('🏀 logoutBtn: ', logoutBtn);
        e.preventDefault();

        try {
            const res = await axios({
                method: 'GET',
                url: '/api/v1/users/logout'
            });
    
            if(res.data.status === 'success') {
                location.assign('/');
            }
    
        } catch (err){
            showAlert('err', 'Greška u odjavljivanju, pokušajte ponovo.');
        }
    });
}


export async function signup(){
    document.querySelector('.open .input--submit').addEventListener('click', async function(e) {
        // e.preventDefault();
        const email = document.querySelector('.open .input--email').value;
        const userName = document.querySelector('.open .input--name').value;
        const password = document.querySelector('.open .input--password').value;
        const passwordConfirm = document.querySelector('.open .input--password_confirm').value;
        // return console.log('🏀 email, password, passwordConfirm, userName: ', email, password, passwordConfirm, userName);

        try{
            const res = await axios({
                method: 'post',
                url: '/api/v1/users/signup',
                data: {
                    name: userName,
                    email,
                    password,
                    passwordConfirm
                }
            });
        
            // console.log('🏀 res.data: ', res.data);
            if(res.data.status === 'success'){
                showAlert('success', res.data.message);
            }
            window.setTimeout(() => location.assign('/'), 5 * 1000); //
    
        } catch(err){
            showAlert('error', err.response.data.message);
        }
    });
}


export async function forgotPassword(){
    document.querySelector('.forgot .input--submit').addEventListener('click', async function(e) {
        // e.preventDefault();
        const email = document.querySelector('.forgot .input--email').value;
        // console.log('🏀 email: ', email);

        try{
            const res = await axios({
                method: 'post',
                url: '/api/v1/users/forgotPassword',
                data: {
                    email
                }
            });
        
            // console.log('🏀 res.data: ', res.data);
            if(res.data.status === 'success'){
                showAlert('success', res.data.message);
            }
            window.setTimeout(() => location.assign('/'), 5 * 1000); //
    
        } catch(err){
            showAlert('error', err.response.data.message);
        }
    });
}


export async function deleteMe(){
    document.querySelector('.delete .input--submit').addEventListener('click', async function(e) {
        const email = document.querySelector('.forgot .input--email').value;

        try{
            const res = await axios({
                method: 'delete',
                url: '/api/v1/users/deleteMe'
            });
        
            // console.log('🏀 res.data: ', res.data);
            if(res.data.status === 'success'){
                showAlert('success', res.data.message);
            }
            window.setTimeout(() => location.assign('/'), 5 * 1000); //
    
        } catch(err){
            showAlert('error', err.response.data.message);
        }
    });
}