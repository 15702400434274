import actionsSlide from './actionsSlide.js';
import mobileMenu from './mobileMenu.js';
import sortProducts from './sort.js';
import { prevP, nextP, clickableNames } from './productsPage.js';
import { showBigPicture, addToCart, fillBreadCrumb } from './productPage.js';
import { deleteItem, goToCart, increaseInCart, deleteItem, whatShows, collectPurchaseData } from './cart.js';
import { login, logout, signup, forgotPassword, deleteMe } from './login.js';
import { redirectOnHomePage } from './accountConfirm.js';
import { resetPassword } from './reset.js';
import { setRabate, whatShowsAdmin, getPurchaseHistory, addProduct, editProduct, deleteProduct, setActionProducts } from './admin.js';
import { map } from './leaflet.js';
import { buyWithCard } from './checkCardPayment.js';
// import changeCatTitle from './changeCatTitle.js';


const pageType = document.querySelector('main').dataset.pagetype;

mobileMenu();
goToCart();
logout();

if(pageType === 'home-page'){
    actionsSlide();
}

if(pageType === 'category'){
    sortProducts();
    prevP();
    nextP();
    clickableNames();
    // changeCatTitle();
}

if(pageType === 'product'){
    showBigPicture();
    actionsSlide();
    addToCart();
    fillBreadCrumb();
}

if (pageType === 'cart'){
    increaseInCart();
    deleteItem();
    whatShows();
    collectPurchaseData();
}

if (pageType === 'login'){
    login();
    signup();
    forgotPassword();
    deleteMe();
}

if (pageType === 'account-confirm'){
    redirectOnHomePage();
}

if (pageType === 'reset'){
    resetPassword();
}

if (pageType === 'admin'){
    whatShowsAdmin();
    setRabate();
    getPurchaseHistory();
    addProduct();
    editProduct();
    deleteProduct();
    setActionProducts();
}

if (pageType === 'contact'){
    map();
}

if (pageType === 'thankYouCardPayment'){
    buyWithCard();
}