

export default function createOptionsUrl(url, original, uniqueString){

    // // 1 - url ne sadrzi query string
    // if(!url.includes('?')){
    //     return `${url}?${original}`;
    // }

    // 2 - url sadrzi query string i ne sadrzi uniqueString
    if(url.includes('?') && !url.includes(uniqueString)){
        return url.replace('?', `?${original}&`);
    }

    // 4 - url sadrzi query string i sadrzi i uniqueString na prvom mestu i druge string parametre
    if(url.includes(`?${uniqueString}`) && url.includes('&')){
        var visak = url.split('?')[1].split('&')[0];
        return url.replace(visak, original);
    }

}