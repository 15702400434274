// import axios from 'axios';

import { showAlert } from './alert.js';



export async function buyWithCard() {

    const paymentSuccessfull = document.getElementById('paymentSuccessfull');
    if(!paymentSuccessfull) return;

    const buyer = JSON.parse(document.getElementById('paymentSuccessfull').dataset.buyerdata);
    // console.log('🏀 buyer: ', buyer);
    // console.log(allSecureResponse);
    
    try {
        
        // 1 - Slanje emaila ka kupcu, kao i ka prodavcu
        // console.log('🏀 sending emails: ');

        await axios({
            url: '/api/v1/bookings/emails',
            method: 'post',
            data: {
                whoPays: buyer.whoPays,
                email: buyer.email,
                fullName: buyer.fullName,
                address: buyer.address,
                postCode: buyer.postCode,
                phone: buyer.phone,
                message: buyer.message,
                acceptanceMethod: buyer.acceptanceMethod,
                selectedPayment: buyer.selectedPayment,
                productsTotalPrice: buyer.productsTotalPrice,
                delivery: buyer.delivery,
                totalPrice: buyer.totalPrice
            }
        });

        // * Bookiranje ako je logovan korisnik
        const signoutBtn = document.querySelector('.signout');
        if (signoutBtn !== null){
            const sendBody = {
                user: signoutBtn.dataset.user_id,
                price: buyer.totalPrice.split(' ')[0].split(',').join('') * 1,
                paymentType: buyer.selectedPayment,
                purchase: signoutBtn.dataset.cart
            };

            // return console.log('🏀 sendBody: ', sendBody);
            
            await axios({
                url: '/api/v1/bookings',
                method: 'post',
                data: sendBody
            });
        }

        // 2 - Brisanje korpe (req.session.cart)
        await axios({
            url: '/cart',
            method: 'delete'
        });

        // 3 - Brisanje req.session.buyerData
        await axios({
            url: '/cart/buyer',
            method: 'delete'
        });

        showAlert('success', 'Uspešna porudžbina. Detalji su poslati na Vaš email.'); // 🧨

        // 5 - Usmeravanje na zeljenu stranu
        window.setTimeout(() => location.assign('/'), 3 * 1000);

    } catch(err){
        console.log(err);
    }
}